import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import * as leaderboard_2019 from '../leaderboard_2019.json';
import * as leaderboard_2020 from '../leaderboard_2020.json';
import * as leaderboard_2021 from '../leaderboard_2021.json';

const styles = theme => ({
    gridContainer: {
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 200,
      },
    rowHeading: {
        color: theme.palette.primary.light,
        fontSize: "1rem"
    },
    rowValue: {
        fontSize: "1rem"
    }
});

class LeaderBoard extends Component {
    render() {
        const { classes } = this.props;
        if (this.props.year === 2019) {
            var rows = leaderboard_2019.teams;
            var ap_suffix = ""
        }
        else if (this.props.year == 2020) {
            var rows = leaderboard_2020.teams;
            var ap_suffix = ""
        }
        else if (this.props.year == 2021) {
            var rows = leaderboard_2021.teams;
            var ap_suffix = "†"
        }
        
        return (
          <div className={classes.gridContainer}>
            <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                <TableCell className={classes.rowHeading}>Team</TableCell>
                <TableCell align="right" className={classes.rowHeading}>AP{ap_suffix}</TableCell>
                <TableCell align="right" className={classes.rowHeading}>APr{ap_suffix}</TableCell>
                <TableCell align="right" className={classes.rowHeading}>APc{ap_suffix}</TableCell>
                <TableCell align="right" className={classes.rowHeading}>APf{ap_suffix}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map(row => (
                <TableRow key={row.name}>
                    <TableCell component="th" scope="row" className={classes.rowValue}>
                    <Link href={row.link}>
                        {row.name}
                    </Link>
                    </TableCell>
                    <TableCell align="right" className={classes.rowValue}>{row.AP.toFixed(1)}</TableCell>
                    <TableCell align="right" className={classes.rowValue}>{row.APr.toFixed(1)}</TableCell>
                    <TableCell align="right" className={classes.rowValue}>{row.APc.toFixed(1)}</TableCell>
                    <TableCell align="right" className={classes.rowValue}>{row.APf.toFixed(1)}</TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
            </Paper>
          </div>
        );
    }
}

export default withWidth()(withStyles(styles)(LeaderBoard));
